<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row" v-if="loading">Cargando...</div>
        <div v-if="!loading">
          <div class="row" v-if="hasSerie">
            Esta computadora ya posee una serie.
          </div>
          <div class="row" v-if="!hasSerie">
            <div class="col-md-4">
              <b-form-group label="Serie factura">
                <b-input-group prepend="F" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-model="serie.serie_factura"
                    required
                    maxlength="3"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Serie boleta">
                <b-input-group prepend="B" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-model="serie.serie_boleta"
                    required
                    maxlength="3"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Serie NC de Factura">
                <b-input-group prepend="F" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-model="serie.serie_nc_factura"
                    required
                    maxlength="3"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Serie NC de boleta">
                <b-input-group prepend="B" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-model="serie.serie_nc_boleta"
                    required
                    maxlength="3"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Serie de vale">
                <b-input-group prepend="V" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-model="serie.serie_vale"
                    required
                    maxlength="3"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Serie de guías de remisión">
                <b-input-group prepend="T" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-model="serie.serie_remision"
                    required
                    maxlength="3"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Punto de venta">
                <b-form-select
                  v-model="serie.warehouse"
                  :options="warehouses"
                  text-field="name"
                  value-field="id"
                  size="sm"
                  class="mt-3"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-card-text>
      <template #footer>
        <b-button variant="primary" @click="saveSerie" v-if="!hasSerie"
          >Guardar
        </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "MachineIdCreate",
  data() {
    return {
      loading: true,
      hasSerie: true,
      serie: {
        serie_factura: `${this.makeid(3)}`,
        serie_boleta: `${this.makeid(3)}`,
        serie_nc_factura: `${this.makeid(3)}`,
        serie_nc_boleta: `${this.makeid(3)}`,
        serie_vale: `${this.makeid(3)}`,
        serie_remision: `${this.makeid(3)}`,
        serie_order: `${this.makeid(3)}`,
        warehouse: null,
      },
      warehouses: [],
    };
  },
  mounted() {
    this.getSerie();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Serie" },
      { title: "Configurar Serie" },
    ]);
    this.getWarehouses();
  },
  methods: {
    getSerie() {
      // Check if I have a uuid in localStorage
      if (localStorage.getItem("pos_key") === null) {
        localStorage.setItem("pos_key", uuidv4());
      }
      ApiService.get(
        `core/machine-id/identifier`,
        `?identifier=${localStorage.pos_key}`
      )
        .then((res) => {
          this.hasSerie = true;
          console.log(res);
        })
        .catch((res) => {
          this.hasSerie = false;
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWarehouses() {
      ApiService.get("core/warehouse", "?page_size=100000&page=1").then(
        (res) => {
          this.warehouses = res.data.results;
        }
      );
    },
    saveSerie() {
      let serie = {
        serie_factura: `F${this.serie.serie_factura}`,
        serie_boleta: `B${this.serie.serie_factura}`,
        serie_nc_factura: `F${this.serie.serie_nc_factura}`,
        serie_nc_boleta: `B${this.serie.serie_nc_boleta}`,
        serie_vale: `V${this.serie.serie_vale}`,
        serie_remision: `V${this.serie.serie_remision}`,
        serie_order: `V${this.serie.serie_order}`,
        uuid: localStorage.pos_key,
        warehouse: this.serie.warehouse,
      };
      ApiService.post("core/machine-id/", serie)
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          window.location.reload();
        });
    },
    makeid(length) {
      let result = "";
      let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    makeToastVariant(message = "Éxito", variant = "success") {
      this.$bvToast.toast(message, {
        title: variant === "success" ? "Éxito" : "Ooops",
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
